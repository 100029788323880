import './App.css';
import React, { useState } from 'react';
import { Container, Image } from 'react-bootstrap';
import Foto from './images/Perfil.jpeg';
import Insta from './images/Instagram.jpg';
import logo from './images/Logo.png';
import Gaming from './images/Gaming.jpg';
import icarolanes from './images/icarolanes.jpg'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const links = [
    { name: "LinkedIn", url: "https://bit.ly/3Nuw0ZX", icon: Foto, troca: false, bg: '' },
    { name: "GitHub", url: "https://github.com/icarolanes", icon: icarolanes, troca: false, bg: '' },
    { name: "Instagram", url: "https://www.instagram.com/icarolanes/", icon: Insta, troca: true, bg: Insta },
    { name: "Email", url: "mailto:icaro_lanes@hotmail.com", icon: Foto, troca: false, bg: '' },
    { name: "Whatsapp", url: "https://bit.ly/3KSepJq", icon: Foto, troca: false, bg: '' },
    { name: "Twitch", url: "https://twitch.tv/icarolanes", icon: logo, troca: true, bg: Gaming },
  ];

  const [image, setImage] = useState(Foto);
  const [background, setBackground] = useState('');
  const [isSpinning, setIsSpinning] = useState(false);
  const [isFading, setIsFading] = useState(false); // Novo estado para controlar o fade

  function handleHover(data) {
    setIsSpinning(true);

    // Altera a imagem na metade da rotação, se shouldSpin for true
    setIsFading(true); // Inicia o fade
    setTimeout(() => {
      setTimeout(() => setImage(data.icon), 150)
      setBackground(data.bg);
      setIsFading(false); // Finaliza o fade
      setTimeout(() => setIsSpinning(false), 300)
    }, 300); // 300ms é a metade de 600ms (duração do spin)
  }

  return (
    <Container className='corpo' fluid>
      <Image
        src={background || ''} // Fundo padrão ou background do estado
        className={`background ${isFading ? 'fade' : ''}`}
        fluid
      />
      <div className="scontainer">
        <img
          src={image}
          alt="Avatar"
          className={`avatar ${isSpinning ? 'spin' : ''}`} // Adiciona a classe "spin" se isSpinning for true
        />
        <h1>Icaro Lanes</h1>

        <div className="links">
          {links.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => handleHover(link)}
              onMouseLeave={() => handleHover({ icon: Foto, bg: '' })}
              className="link"
            >
              {link.name}
            </a>
          ))}
        </div>
      </div>
    </Container>
  );
}

export default App;
